.mymodalclient {
    position: fixed;
    top: 50%;
    left: 50%;
    width: auto;
    transform: translate(-50%, -50%);
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    font-size: 16px;
    border-radius: 4px;
    outline: none;
    padding: 20px;
}

@media screen and (min-width: 1024px) and (max-height: 1310px) {

    /* Laptop */
    .mymodalclient {
        position: fixed;
        top: 50%;
        left: 55%;
        width: 400px;
        transform: translate(-50%, -50%);
        border: 1px solid #ccc;
        background: #fff;
        overflow: auto;
        font-size: 16px;
        border-radius: 4px;
        outline: none;
        padding: 20px
    }
}

@media only screen and (min-device-width: 360px) and (max-device-width: 640px) {
    .mymodalclient {
        position: fixed;
        top: 50%;
        left: 50%;
        width: auto;
        transform: translate(-50%, -50%);
        border: 1px solid #ccc;
        background: #fff;
        overflow: auto;
        font-size: 16px;
        border-radius: 4px;
        outline: none;
        padding: 20px
    }
}

.myoverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
}

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
    overflow-y: hidden;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}

.selected {
    font-size: 16px;
}