body {
    background-color: #eeeeee;
}

.scrollView {
    width: 100%;
    height: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    transform: rotateX(180deg);
}

.scrollView p {
    transform: rotateX(180deg);
}


/* 
.form-label{
} */

p {
    font-size: 14px;
}

.buttoncolor {
    color: #ffffff;
}

.form-label {
    font-size: 14px;
}

.form-labe {
    margin-left: -50px;
}

h3 {
    font-size: 18px;
}

.file-upload {
    background-color: #ffffff;
    width: 600px;
    margin: 0 auto;
    padding: 18px;
}

a.menu_links:hover {
    cursor: pointer;
}

#input {
    font-size: 14px;
}

#gender {
    padding: 14px;
}

#id {
    font-size: 14px;
}


/* .form-control {} */

.file-upload-btn:hover {
    background: #0070ba;
    color: #ffffff;
    transition: all 0.2s ease;
    cursor: pointer;
}

.file-upload-btn:active {
    border: 0;
    transition: all 0.2s ease;
}

.file-upload-content {
    display: none;
    text-align: center;
}

.Links {
    font-style: bold;
    font-size: 14px;
}

.file-upload-input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
}

.image-upload-wrap {
    margin-top: 20px;
    border: 1px dotted #0070ba;
    position: relative;
}

.image-dropping,
.image-upload-wrap:hover {
    background-color: lightgray;
    border: 1px dashed #0070ba;
    color: white;
}

.image-title-wrap {
    padding: 0 14px 14px 14px;
    color: #222;
}

.drag-text {
    text-align: center;
}

.drag-text h3 {
    font-weight: 100;
    text-transform: uppercase;
    color: #0070ba;
    padding: 60px 0;
}

.file-upload-image {
    max-height: 200px;
    max-width: 200px;
    margin: auto;
    padding: 20px;
}

.remove-image {
    width: 200px;
    margin: 0;
    color: #fff;
    background: #cd4535;
    border: none;
    padding: 10px;
    border-radius: 4px;
    border-bottom: 4px solid #b02818;
    transition: all 0.2s ease;
    outline: none;
    text-transform: uppercase;
    font-weight: 700;
}

.btn-primary {
    border: none;
    padding: 5px;
    color: "primary";
    text-align: center;
    border-radius: 5px;
    font-size: 14px;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 4px 2px;
}

.horizontal {
    display: block;
    background-color: #eee;
    margin-left: auto;
    margin-right: auto;
}

.btn-success {
    border: none;
    color: "primary";
    text-align: center;
    border-radius: 5px;
    font-size: 14px;
    text-decoration: none;
    margin: 4px 2px;
}

.bold-text {
    font-size: 14px;
}

.primary {
    font-size: 3em;
}

.btn-info {
    border: none;
    color: "info";
    font-size: 14px;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    display: inline-block;
    margin: 4px 2px;
}

.btn-danger {
    border: none;
    font-size: 14px;
    color: "primary";
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    margin: 4px 2px;
}

.drop {
    border-color: black;
}

p {
    font-size: 14px;
}

.cell {
    border: 0;
    white-space: nowrap;
    height: 55px;
    margin-top: 20px;
    padding-top: 20em;
    /* text-align: center; */
    margin-right: -0.09599em;
    margin: 0;
    outline: 0;
    vertical-align: middle !important;
    border: 0px !important;
    border-bottom: 0px !important;
}

.transaction {
    border: 0;
    white-space: nowrap;
    height: 55px;
    margin-top: 20px;
    padding-top: 20em;
    text-align: center;
    margin-right: -0.09599em;
    margin: 0;
    outline: 0;
    vertical-align: middle !important;
    border: 0px !important;
    border-bottom: 0px !important;
}

@media screen and (min-width: 1px) and (max-width: 1365px) {
    .account {
        width: 100%;
    }
}

@media screen and (min-width: 1366px) {
    .account {
        width: 100%;
    }
}

.tsc {
    border: 0;
    height: 55px;
    vertical-align: middle !important;
    border: 0px !important;
    border-bottom: 0px !important;
}

.bold-text {
    /* font-size: 14px; */
}

.primary {
    font-size: 3em;
}

.customDatePickerWidth,
.customDatePickerWidth>div.react-datepicker-wrapper,
.customDatePickerWidth>div>div.react-datepicker__input-container .customDatePickerWidth>div>div.react-datepicker__input-container input {
    width: 100%;
    color: hsl(0, 0%, 80%);
}

h4 {
    font-size: 15px;
}

h5 {
    font-size: 15px;
}

#input {
    font-size: 14px;
    color: rgb(107, 106, 106);
    border-color: hsl(0, 0%, 80%);
}

#county_name {
    font-size: 14px;
}

#blah {
    vertical-align: -2px;
}

input[type='checkbox'] {
    width: 14px;
    height: 14px;
    vertical-align: bottom;
}

label.checkbox {
    vertical-align: top;
    line-height: 14px;
    margin: 2px 0;
    display: block;
    height: 14px;
}

.modal-content {
    height: 300px;
}

#tsc {
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    outline: 0;
    border: 0px !important;
    margin-bottom: 30px;
    border-bottom: 0px !important;
}

.remove-image:hover {
    background: #c13b2a;
    color: #ffffff;
    transition: all 0.2s ease;
    cursor: pointer;
}

.remove-image:active {
    border: 0;
    transition: all 0.2s ease;
}

.modal-footer {
    background: #ecf0f1;
    border-color: #dee4e7;
}

.custom-header-style {
    display: 'inline-block';
}